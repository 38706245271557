<template>
	<el-container>
		<el-container>
			<el-aside width="30%" style="padding: 15px;">
				<div style="margin-top: 15px;display: flex;flex-direction: row;justify-content: space-between;">
					<el-input v-model="searth.company" placeholder="公司" style="width: 80px;"></el-input>
					<el-input v-model="searth.nickname" placeholder="姓名" style="width: 70px;"></el-input>
					<el-input v-model="searth.branch_1" placeholder="单位" style="width: 70px;"></el-input>
					<el-input v-model="searth.job" placeholder="职务" style="width: 70px;"></el-input>
					<el-button @click="searchUser" type="primary" style="width: 70px;">查询</el-button>
				</div>
				<el-table :data="userData" stripe style="width: 100%" @row-click="userRow" :highlight-current-row="true">
					<el-table-column prop="company" label="公司">
					</el-table-column>
					<el-table-column prop="nickname" label="姓名">
					</el-table-column>
					<el-table-column prop="branch_1" label="单位">
					</el-table-column>
					<el-table-column prop="job" label="职务">
					</el-table-column>
				</el-table>
				<div>
					<el-pagination background layout="prev, pager, next" :current-page="currentPage" @current-change="currentChange" :hide-on-single-page="true" :total="pageTotal" :page-size="pageCount"></el-pagination>
				</div>
			</el-aside>
			<el-container>
				<el-main>
					<div class="m_title">
						在线设备{{onLine}}台
					</div>
					<el-amap vid="amapDemo" :zoom="11" :center="infoLocation">
						<el-amap-marker v-for="(marker,index) in userPoint" :label="index"
							:position="[marker.location.Lon, marker.location.Lat]" :key="index" :vid="index"
							:events="marker.eventClick">
						</el-amap-marker>
						<el-amap-info-window :position="infoLocation" :visible="showInfo" :events="infoEvent">
							<div style="width: 300px;height: 122px;font-size: 20px;line-height: 10px;">
								<span style="font-size: 20px;font-weight: 600;margin-right: 15px;">{{ userInfo.nickname}}</span>
								<span>{{ userInfo.location.upload_time || '' }}</span>
								<p style="font-size: 14px;line-height: 16px;" v-if="address">{{ userInfo.online}}  {{address}}</p>
								<p style="font-size: 14px;line-height: 16px;">
									{{userInfo.company || ''}} {{userInfo.gender || ''}}
									{{(new Date().getFullYear() - (userInfo.birthday || '').split('-')[0]+1) || '' }} {{userInfo.branch_1 || ''}} {{userInfo.job || ''}}
								</p>
								<p v-if="userInfo.emotion_mean!=-1&&userInfo.tired_mean!=-1" style="font-size: 14px;line-height: 16px;">
									情绪：{{userInfo.emotion_mean}}
									<span class="statusDot"
										v-bind:class="{dangerDot:userInfo.emotion_mean<50,warmDot:userInfo.emotion_mean<70}">
									</span>
									疲劳：{{userInfo.tired_mean}}
									<span class="statusDot"
										v-bind:class="{dangerDot:userInfo.tired_mean>69,warmDot:userInfo.tired_mean>49}">
									</span>
									压力：{{userInfo.stress_mean}}
									<span class="statusDot"
										v-bind:class="{dangerDot:userInfo.stress_mean>69,warmDot:userInfo.stress_mean>49}">
									</span>
								</p>
								<p v-else>暂无数据</p>
							</div>
						</el-amap-info-window>
					</el-amap>
				</el-main>
			</el-container>
		</el-container>
	</el-container>
</template>

<script>
	
	import baseUrl from "../utils/baseUrl.js"
	import {
		getUser,
	} from "../api/visualiztion.js"
	const gdKey = '94a2ff8a0842f42fb78787c63ecd0a35';
	const gdUrl = 'https://restapi.amap.com/v3/geocode/regeo';
	import axios from 'axios'
	import {
		equipment_list
	} from '../api/equipment'
	export default {
		data() {
			return {
				onLine:0,
				searth:{
					jop:'',
					nickname:'',
					branch_1:'',
					job:'',
				},
				company:'',
				branch_1:'',
				address:'',
				currentPage:1,
				userInfo: {location:{}},
				showInfo: false,
				infoLocation: [114.064552, 22.588456],
				userData: [],
				userPoint:[],
				pageTotal: 0,
				pageCount: 10,
				input: '',
				onLineArr:[],
				infoEvent: {
					close: (e) => {
						this.showInfo = false;
					}
				}
			}
		},
		mounted() {
			this.company = sessionStorage.getItem('company');
			let branch_content = sessionStorage.getItem('branch-content');
			if(branch_content && branch_content!='null'){
				this.branch_1 = branch_content
			}else{
				this.branch_1 = ''
			}
			this.equipmentList()
		},
		methods: {
			equipmentList() {
				let branchs = this.branch_1.split('|')
				equipment_list(this.company,'',branchs[0]||'',branchs[1]||'',1,1000).then(res => {
					if (res.status != 200) {
						return this.$message.error('获取人员列表失败')
					}
					for(let i of res.data.equipmentData){
						if(i.is_online == 'online'){
							this.onLine +=1;
							this.onLineArr.push(i.imei)
						}
					}
					this.getUserList({pageNum:this.currentPage,pageCount:this.pageCount,company:this.company,branch_1:this.branch_1})
				})
			},
			userRow(row){
				if(!row.location){
					return this.$notify.info({
					title: '提示',
					message: '此用户暂无数据！',
				});
				}
				this.userInfo = row
				this.infoLocation = [row.location.Lon,row.location.Lat]
					axios.get(gdUrl,{
						params:{
							key:gdKey,
							location:`${row.location.Lon}, ${row.location.Lat}`
						}
					}).then(res =>{
						this.address = res.data.regeocode.formatted_address||''
						this.showInfo = true;
					}).catch(err =>{
						this.address = ''
						this.showInfo = true;
					})
			},
			currentChange(e){
				this.currentPage = e
				// this.getUserList({pageNum:this.currentPage,pageCount:this.pageCount,company:this.company})
				this.getUserList({pageNum:this.currentPage,pageCount:this.pageCount,company:this.company,branch_1:this.branch_1})
			},
			searchUser(){
				this.currentPage = 1;
				let searchData = {pageNum:this.currentPage,pageCount:this.pageCount,company:this.company};
					for(let i in this.searth){
						if(this.searth[i].trim()){
							this.searth[i] = this.searth[i].trim()
						}
					}
					searchData = {...this.searth,...searchData};
				this.getUserList(searchData)
			},
			getUserList(data) {
				const loading = this.$loading({
					lock: true,
					text: '请稍等,数据加载中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				getUser(data).then(res => {
					this.userData= [];
					this.userPoint=[];
					this.pageTotal = res.data.pageTotal;
					for (let i in res.data.hourDates) {
						res.data.hourDates[i].online = '离线';
						for(let online in this.onLineArr){
							if(res.data.hourDates[i].imei == this.onLineArr[online]){
								res.data.hourDates[i].online = '在线';
							}
						}
						res.data.hourDates[i].showInfo = false;
						if(res.data.hourDates[i].location){
							res.data.hourDates[i].eventClick = {
								click: (e) => {
									axios.get(gdUrl,{
										params:{
											key:gdKey,
											location:`${this.userData[i].location.Lon}, ${this.userData[i].location.Lat}`
										}
									}).then(res =>{
										this.userInfo = this.userData[i];
										this.infoLocation = [this.userData[i].location.Lon, this.userData[i]
											.location.Lat
										]
										this.address = res.data.regeocode.formatted_address||''
										this.showInfo = true;
									}).catch(err =>{
										this.userInfo = this.userData[i];
										this.infoLocation = [this.userData[i].location.Lon, this.userData[i]
											.location.Lat
										]
										this.address = ''
										this.showInfo = true;
									})
								}
							}
							this.userPoint.push(res.data.hourDates[i])
						}
						this.userData.push(res.data.hourDates[i])
					}
					if(this.userData[0].location){
						this.infoLocation = [this.userData[0].location.Lon, this.userData[0]
							.location.Lat
						]
					}
					loading.close();
				}).catch(err => {
				loading.close();
				this.$notify.error({
					title: '数据加载失败！',
					message: err.message
				});
			})
			}
		},
	}
</script>

<style>
	.el-container {
		height: 93vh;
	}

	.m_title {
		width: 100%;
		height: 8vh;
		line-height: 8vh;
		font-size: .4rem;
		text-align: center;
	}
	.el-header {
		height: 7vh;
		line-height: 7vh;
	}
	.el-footer {
		height: 5vh;
	}
	.el-table{
		cursor: pointer;
	}
	.el-header,
	.el-footer {
		background-color: #B3C0D1;
		color: #333;
		text-align: center;
		/* line-height: 60px; */
	}

	.el-aside {
		background-color: #fff;
		color: #333;
		text-align: center;
		/* line-height: 200px; */
	}

	.el-main {
		background-color: #E9EEF3;
		color: #333;
		text-align: center;
		/* line-height: 160px; */
	}

	.bm-view {
		height: 75vh;
	}
	.el-vue-amap-container{
		height: 80vh !important;
	}
	.statusDot {
		background-color: #aaff00;
		width: 14px;
		height: 14px;
		display: inline-block;
		position: relative;
		top: 1px;
		border-radius: 50%;
	}

	.warmDot {
		background-color: yellow;
	}

	.infoDot {
		background-color: #aaff00;
	}

	.dangerDot {
		background-color: red;
	}
</style>
